<template>
    <button class="btn location-button" data-bs-toggle="modal"
        data-bs-target="#locationModal">
        <span class="location-arrow">
            <i data-feather="map-pin"></i>
        </span>
        <span class="locat-name" v-if="CitySearch">{{ selectedCountry.text }} , {{ CitySearch }}</span>
        <span class="locat-name" v-else>{{ selectedCountry.text }}</span>
        <i class="fa-solid fa-angle-down"></i>
    </button>



    <!-- Location Modal Start -->
     <div class="modal location-modal fade theme-modal" id="locationModal" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true" ref="locationselectContainer">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Choose your Delivery Location</h5>
                    <p class="mt-1 text-content">Enter your address and we will specify the offer for your area.</p>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa-solid fa-xmark" style="margin-left: 2px;"></i>
                    </button>
                </div>
                
                <div class="modal-body">
                    <div class="location-list">
                        <div class="disabled-box">
                            <h6>Select Country</h6>
                        </div>
                        <div class="mb-2">
                            <model-select :options="CountryOptions" name="country_id"
                                v-model="selectedCountry"
                                placeholder="Select a country Here..."
                                required>
                                <template v-slot="{ option }">
                                <span>{{ option.text }}</span>
                                </template>
                        </model-select>
                        </div>

                        <div class="disabled-box">
                            <h6>Search City or PostalCode</h6>
                        </div>
                        
                        <div class="search-input mb-2">
                            <input v-model="CitySearch" type="search" class="form-control" placeholder="Search Your Area"/>
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </div>

                       

                        <ul class="location-select custom-height" v-if="CitySearch">
                            <li v-for="data in filteredCities" :key="data">
                                <a href="javascript:void(0)" @click="selectCityName(data.name)">
                                <h6>{{ data.name }}</h6>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Location Modal End -->
</template>


<script>
import { ModelSelect } from 'vue-search-select'
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
    name: 'deli-location',
  components: { 
    ModelSelect
  },
   data() {
    return {
      selectedCountry: { value: "", text: "" ,countryCode:""},
      countryData:[],
      fullPage: false,
      deliverycityData:[],
      CitySearch: null,
    //  CitySearch:"",
      selectedCountryCode:null
    };
  },
   computed: {
    CountryOptions() {
            return this.countryData.map(item => ({
                value: item.id,
                text: item.name,
                countryCode:item.country_code,
            }));
        },
    filteredCities() {
        if (this.CitySearch) {
            const query = this.CitySearch.toLowerCase();
           return this.deliverycityData.filter((city) =>
            city.name.toLowerCase().includes(query)
            );
        }else{
            return this.deliverycityData;
        }
      
    },
     
  },
  watch: {
        selectedCountry(newValue) {
            this.fetchDeliverycityData(newValue.value);
           // console.log(newValue);
            if (Cookies.get("selectedCountryCode") != newValue.countryCode) {
                this.CitySearch = null;
                Cookies.remove("CitySearch");
            }
            
            Cookies.set("selectedCountryCode", newValue.countryCode , { expires: 6 });
            Cookies.set("SelectedCountryname", newValue.text , { expires: 6 });
        },
       
    },
  methods: {
    selectedData(){
        if (Cookies.get("selectedCountryCode")) {
            this.selectedCountryCode = Cookies.get("selectedCountryCode");
            const selectedCountryOptions = this.CountryOptions.find(option => option.countryCode === this.selectedCountryCode);
            if (selectedCountryOptions) {
                this.selectedCountry = selectedCountryOptions;
            } else {
                console.error('Selected Country option not found');
            }
        }else{
            this.ipbasedfetchCountryData();
          //  console.log('ip',this.selectedCountryCode);
            const selectedCountryOptions = this.CountryOptions.find(option => option.countryCode === this.selectedCountryCode);
            if (selectedCountryOptions) {
                this.selectedCountry = selectedCountryOptions;
            } else {
                console.error('Selected Country option not found');
            }
           
        }
        
    },
    async fetchCountryData() {
        try {
            const response = await axios.get('delivery/country'); // Replace with your API endpoint
            this.countryData = response.data;
            this.selectedData();
        } catch (error) {
            console.error('Error fetching delivery country data:', error);
        }

    },
    async ipbasedfetchCountryData() {
        try {
            const response = await axios.get('https://ipapi.co/json/');
            this.selectedCountryCode = response.data.country_code;
         } catch (error) {
            console.error('Error fetching country code:', error);
        } 

    },
    async fetchDeliverycityData(id) {
        let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.locationselectContainer,
                canCancel: true,
                onCancel: this.onCancel,
            });
        try {
            const response = await axios.get(`delivery/city/${id}`); // Replace with your API endpoint
            this.deliverycityData = response.data;
            loader.hide();
        } catch (error) {
            loader.hide();
            console.error('Error fetching delivery city data:', error);
        }

    },

    selectCityName(name) {
      this.CitySearch = name;
      Cookies.set("CitySearch", name, { expires: 6 });
      window.location.reload();
    },
   
  },
  mounted() {
    this.ipbasedfetchCountryData();
    if (Cookies.get("CitySearch")) {
            this.CitySearch = Cookies.get("CitySearch"); 
        }else{
            this.CitySearch = null;
        }
        this.fetchCountryData();
        const locationModal = this.$refs.locationselectContainer;

        // Attach event listener to reload the page when the modal is fully hidden
        locationModal.addEventListener('hidden.bs.modal', () => {
            location.reload();
        });
  }, 
  unmounted() {
    // Remove the resize event listener when the component is unmounted
   
  },
  
};
</script>


<style scoped>
.location-modal .modal-dialog .modal-content .modal-body .location-list .disabled-box{
    margin-top:-15px
}

header .top-nav .navbar-top .middle-box .location-box button i {
    margin-left: 3px;
    color: var(--themecolor);
}

header .location-box button .locat-name {
    font-size: 13px;
    font-weight: 600;
    color: var(--theme-color);
}
</style>