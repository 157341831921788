<template>
        <div class="delivery-login-box">
        <div class="delivery-icon">
            <i data-feather="user"></i>
        </div>
        <div class="delivery-detail">
            <h6>Hello,</h6>
            <h5>My Account</h5>
        </div>
    </div>

    <div class="onhover-div onhover-div-login">
        <ul class="user-box-name" v-if="LoginStatus == 'true'">
            <li class="product-box-contain">
                <i></i>
                <RouterLink :to="{ name: 'CustomerAccountDashboardHome', params: { id: LoginCustomerId }}">Dashboard</RouterLink>
            </li>
            <li class="product-box-contain">
                <i></i>
                <a @click="logout">Logout</a>
            </li>
        </ul>
        <ul class="user-box-name" v-else>
            <li class="product-box-contain">
                <i></i>
                <RouterLink to="/login">Log In</RouterLink>
            </li>

            <li class="product-box-contain">
                <RouterLink to="/register">Register</RouterLink>
            </li>

        </ul>
    </div>
</template>

<script>
import { RouterLink } from 'vue-router'
//import router from '../router/index';
import axios from 'axios';
import Cookies from 'js-cookie';

export default {

    components: {
        RouterLink
    },
     computed: {
       
        
    },
     data() {
        return {          
           language:null,
           LoginCustomerId:null,
           LoginStatus:null
        };
    },
   props: {
    
    },  

    setup() {
       
        return {
            

        };
    },
     methods: {
        async logout() {
            var token = Cookies.get("CustomerBearedtoken");

            // console.log('token',token);
            
            const headers = { Authorization: `Bearer ${token}` };
        

            try {
                const response = await axios.post('Customer/logouts', {} ,{ headers }); // Replace with your API endpoint
            // console.log('data',response.data);
            if(response.data.success == true){
                Cookies.remove('LoginStatus');
                Cookies.remove('CustomerBearedtoken');
                Cookies.remove('CustomerLoginId');
                Cookies.remove('CustomerLoginCuntryCode');
                Cookies.remove('CustomerLoginCityName');
                this.$router.push({ name: 'HomeContent' })
                .catch(() => {})
                .finally(() => {
                    // Reload the window
                    window.location.reload();
                });
            }
            } catch (error) {
                
                console.error('Error customer logout data:', error);
            }
        },
    },

    mounted() {   
        if(Cookies.get("CookiesLanguageCode")){
        this.language = Cookies.get("CookiesLanguageCode"); 
       }else{
        this.language = 'en';
       }
       this.LoginCustomerId = Cookies.get("CustomerLoginId");
       this.LoginStatus = Cookies.get("LoginStatus");
    }
};
</script>