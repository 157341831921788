<template>
    <button type="button" class="btn p-0 position-relative header-wishlist">
        <i data-feather="shopping-cart"></i>
        <span class="position-absolute top-0 start-100 translate-middle badge">{{ count }}
            <span class="visually-hidden">card items</span>
        </span>
    </button>
</template>

<script>

export default {
    components: {
        
    },
    props: {
        count: {
            type: Number,
            required: true
        }
    },
    computed: {

    },
    data() {
        return {
            
        };
    },
   

    setup() {

        return {


        };
    },
    methods: {
    

    },

    mounted() {
        
    }

}
</script>