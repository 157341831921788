export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "deals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbiedingen"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evenementens"])},
  "userlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])},
  "vendorlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkoper"])},
  "socialDeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociale overeenkomst"])},
  "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwsbrief ontvangen"])},
  "dealsinflevoland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbiedingen in Flevoland"])},
  "dealsnear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbiedingen dichtbij"])},
  "RestaurantdealsFlevoland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant aanbiedingen Flevoland"])},
  "HoteldealsFlevoland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel aanbiedingen Flevoland"])},
  "Howitworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe het werkt"])},
  "Vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacatures"])},
  "Internships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stages"])},
  "Developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkelaar"])},
  "SharingisCaring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delen is zorgzaam"])},
  "RecommendsocialDealandearn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beveel social Deal aan en verdien"])},
  "Spontaan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spontaan"])},
  "LastMinuteDinnerapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Minute Dinner app"])},
  "LastMinuteDinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diner op het laatste moment"])},
  "luxurygateway.com-hoteldeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["luxurygateway.com - hotel aanbiedingen"])},
  "SocialDealexperiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ervaringen met sociale deals"])},
  "Privacypolicy/GDPR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacybeleid /AVG"])},
  "Bugbountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bug Bounty"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekening"])},
  "Sign Up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evenwicht"])},
  "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waardebonnen"])},
  "GiftWrap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadeaupapier"])},
  "SocialDealGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociale Deal cadeaubon"])},
  "CustomerService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantenservice"])},
  "DownloadtheSocialDealapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download de Sociale Deal-app"])},
  "Frequentlyaskquestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veel gestelde vragen"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "Business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
  "CollabratewithSocialDeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werk samen met Sociale Deal"])},
  "AdvertiseonSocialDeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promoot uw bedrijf op Sociale Deal"])},
  "WorkatSocialDeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkt bij Sociale Deal"])},
  "DoyouinternshipatSocialDeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loop je stage bij Social Deal"])},
  "WorkasadeveloperatSocialDeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werk als ontwikkelaar bij Social Deal"])},
  "PartnerPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerportaal"])},
  "SocialDealPartnerapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Deal Partner-app"])},
  "Companyinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsinfo"])},
  "netharland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederland"])},
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recensies"])},
  "directions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routebeschrijving"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "highlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogtepunten"])},
  "thefineprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Kleine Lettertjes"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korting"])},
  "timeremaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overgebleven Tijd"])},
  "fofree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis"])},
  "shareoffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deel deze deal met je vrienden. Als drie vrienden dezelfde deal kopen, dan is je voucher ! gratis voor jou !"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "perfect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
  "profileinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profielinformatie"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedrijf"])},
  "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkels"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productens"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "productReviewRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productbeoordeling"])},
  "eventReviewRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebeurtenisbeoordeling"])},
  "findlocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek locatie"])}
}