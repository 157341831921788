<script>
import AddressBar from './AddressBar.vue';
import WelcomeMsg from './WelcomeMsg.vue';
import LanguageSelector from './LanguageSelector.vue';
import CurrencySelector from './CurrencySelector.vue';
import LogoIcon from './LogoIcon.vue';
import DeliveryLocation from './DeliveryLocation.vue';
import DeliveryLocationMobile from './DeliveryLocationMobile.vue';
//import SearchBox from './SearchBox.vue';
//import WishlistIcon from './WishlistIcon.vue';
import CartIcon from './CartIcon.vue';
import CartDropdown from './CartDropdown.vue';
import UserIcon from './UserIcon.vue';
import CategorySet from './CategorySet.vue';
import { RouterLink } from "vue-router";
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
    components: {
        AddressBar,
        WelcomeMsg,
        LanguageSelector,
        CurrencySelector,
        LogoIcon,
        DeliveryLocation,
        DeliveryLocationMobile,
       // WishlistIcon,
        CartIcon,
        CartDropdown,
        UserIcon,
        CategorySet,
        RouterLink
    },
    name: 'header-nav',
    data() {
        return {
            PageName_left: 'Welcome To Customer Dashboard',
            PageName_right: 'Customer Dashboard',
            timer: null,
            fullPage: false,
            count: 0,
            cardData: [],
            loginStatus:null,
            searchQuery: '',
            items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'],
            dropdownVisible: false,
            dropdown2Visible: false,
            TitleCategory: '',
            CategoryId: '',
            categoryData: [],
            subcategoryData: [],
            searchHistory:[],
            searchPlaceholder:'I am Searching for ....',
            activeItemId:null,
            language:null,
            showDropdown: false,
            CombineArray:[],
            filteredItems:[],
            SearchAllData:[]
        };
    },
    props: {

    },
    computed: {
        // filteredItems() {
           
        //    var Array = [];

        //     if (this.searchQuery) {
        //         Array = this.CombineArray.filter(item =>
        //             item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        //         );
        //     } else {
        //         Array = this.CombineArray;
        //     }

        //     const uniqueItems = Array.reduce((acc, item) => {
        //         if (!acc.find(i => i.name === item.name)) {
        //             acc.push(item);
        //         }
        //         return acc;
        //     }, []);

        //     return uniqueItems;
        // },
    },
    setup() {

        return {
           

        };
    },
    methods: {
        hideDropdown() {
            // Delay hiding the dropdown to allow click event to register
            setTimeout(() => {
                this.showDropdown = false;
            }, 200);
        },

        handleRouterLinkClick(dealName) {
            let routeName = 'DealShopPage';

            this.$router.push({
                name: routeName,
                params: { dealName: dealName },
            })
            .catch(() => {})
            .finally(() => {
                // Reload the window
                window.location.reload();
            });
        },
        handleRouterLinkClicknew(routeName) {
            this.$router.push({
                name: routeName,
            })
            .catch(() => {})
            .finally(() => {
                // Reload the window
                window.location.reload();
            });
        },
        RedirectShopPage(data) {
           
            this.searchPlaceholder = data.name;
            
            if(localStorage.getItem('lastSearchCategory')){
                localStorage.removeItem('lastSearchCategory');
            }
            localStorage.setItem('lastSearchCategory', data.name);

             // Check if the provided category_id already exists in the array
            if (!this.searchHistory.some(item => item.name === data.name)) {
                // Push new data into the array
                this.searchHistory.push({ categoryIds: data.categoryIds, name: data.name });

                // Store the updated array in localStorage
                localStorage.setItem('categorySearchHistory', JSON.stringify(this.searchHistory));
            }
            this.$router.push({ name: 'SearchShopPage', params: { CategoryId: data.categoryIds }, query:{name : data.name}})
            .catch(() => {})
            .finally(() => {
                // Reload the window
                window.location.reload();
            });
        },
        async fetchCardData(id, token) {

            // console.log('Form Data:', token);
            var cookieLanguage = Cookies.get("CookiesLanguageCode");
            const headers = { Authorization: `Bearer ${token}` };
            var data = { customer_id: id,language:cookieLanguage }
            try {
                const response = await axios.post('Customer/carddata', data, { headers })
                //   console.log('abc',response.data);
                this.count = response.data.count_data;
                this.cardData = response.data.card_data;
            } catch (error) {
                console.error('Error fetching card data:', error);
                if (Cookies.get("LoginStatus") == 'true') {
                    Cookies.remove("LoginStatus");
                    Cookies.remove("CustomerBearedtoken");
                    Cookies.remove("CustomerLoginId");
                    window.location.reload();
                }
                
                

            }
        },

        async fetchCategoryData(language) {
        try {
          const response = await axios.get(`home/searchindexdata?language=${language}`);
          this.categoryData = response.data;
          this.SearchAllData = [...this.categoryData, ...this.searchHistory];

          this.SearchAllData = this.SearchAllData.reduce((acc, item) => {
                if (!acc.find(i => i.name === item.name)) {
                    acc.push(item);
                }
                return acc;
            }, []);
        } catch (error) {
          console.error('Error fetching Category data:', error);
        }
      },     
        redirectTowishList(){
            if(this.loginStatus == true){
                window.open('/shop/Wishlist', '_blank');
            }else{
                this.$router.push('/login');
            }
        },
        toggleDropdown() {
             this.dropdownVisible = !this.dropdownVisible;
        },
              
        clearallHistory(){
            localStorage.removeItem('categorySearchHistory');
            localStorage.removeItem('lastSearchCategory');
            this.searchPlaceholder = 'I am searching for ....'
            
            this.CombineArray = [];
            this.SearchAllData = this.categoryData;
        },
        SearchSubmit(){
            
            var ArrayNew = [];
            ArrayNew = this.SearchAllData.filter(item =>
                        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                    );

            const uniqueItems = ArrayNew.reduce((acc, item) => {
                if (!acc.find(i => i.name === item.name)) {
                    acc.push(item);
                }
                return acc;
            }, []);

            // Step 1: Extract all categoryIds
                let allCategoryIds = uniqueItems.map(item => item.categoryIds);

            // Step 2: Split each categoryId string by commas
            let splitCategoryIds = allCategoryIds.flatMap(ids => ids.split(','));

            // Step 3: Convert to Set to remove duplicates, then back to array
            let uniqueCategoryIds = [...new Set(splitCategoryIds)];
            let finalCategoryIds = 0;
            if(uniqueCategoryIds.length > 0){
                // Step 4: Join the unique ids into a single string
                finalCategoryIds = uniqueCategoryIds.join(',');
            }


            if(localStorage.getItem('lastSearchCategory')){
                localStorage.removeItem('lastSearchCategory');
            }
            localStorage.setItem('lastSearchCategory', this.searchQuery);

             // Check if the provided category_id already exists in the array
            if (!this.searchHistory.some(item => item.name === this.searchQuery)) {
                // Push new data into the array
                this.searchHistory.push({ categoryIds: finalCategoryIds, name: this.searchQuery });

                // Store the updated array in localStorage
                localStorage.setItem('categorySearchHistory', JSON.stringify(this.searchHistory));
            }
            // console.log('history',this.searchHistory);
            this.$router.push({ name: 'SearchShopPage', params: { CategoryId: finalCategoryIds }, query:{name : this.searchQuery}})
            .catch(() => {})
            .finally(() => {
                // Reload the window
                window.location.reload();
            });

        }
        // clearspecificHistory(category_id,name){
        //     this.searchHistory = this.searchHistory.filter(item => item.id !== category_id);

        //     // Update the localStorage with the modified array
        //     localStorage.setItem('categorySearchHistory', JSON.stringify(this.searchHistory));
        //     if(name == localStorage.getItem('lastSearchCategory')){
        //         localStorage.removeItem('lastSearchCategory');
        //     }
        // }

    },
    watch: {
        searchQuery(val){
            if(val == null){
                
                this.filteredItems = this.CombineArray;
            }else{
                var Array = [];
                Array = this.categoryData.filter(item =>
                        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                    );

                    const uniqueItems = Array.reduce((acc, item) => {
                        if (!acc.find(i => i.name === item.name)) {
                            acc.push(item);
                        }
                        return acc;
                    }, []);

                    this.filteredItems = uniqueItems;  
            }
        }
    },

    mounted() {
        
    if(Cookies.get("CookiesLanguageCode")){
        this.language = Cookies.get("CookiesLanguageCode"); 
       }else{
        this.language = 'en';
       }

       this.fetchCategoryData(this.language);

        var token = Cookies.get("CustomerBearedtoken");
       
          // console.log('login',this.loginStatus); 
        if (Cookies.get("LoginStatus") == 'true') {
            var customer_id = Cookies.get("CustomerLoginId");
            this.fetchCardData(customer_id, token);
            this.loginStatus = true;
        }
       
        this.searchQuery = null;
        if(localStorage.getItem('lastSearchCategory')){
            this.searchPlaceholder = localStorage.getItem('lastSearchCategory');
        }  

    },
    created(){
        let searchCategoryData = localStorage.getItem('categorySearchHistory');
        if(searchCategoryData){
            this.searchHistory = JSON.parse(searchCategoryData);
        }
         this.CombineArray = this.searchHistory;
        
    }

}
</script>
<template>
    <div id="header-nav">
        <!-- Header Start -->
        <header class="pb-md-4 pb-0">
            <div class="header-top">
                <div class="container-fluid-lg">
                    <div class="row">
                        <!-- address bar -->
                        <AddressBar />

                        <div class="col-xxl-6 col-lg-9 d-lg-block d-none">
                            <div class="header-offer">
                                <!-- welcome msg -->
                                <WelcomeMsg />
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <ul class="about-list right-nav-about">
                                <!-- language selector -->
                                <li class="right-nav-list">
                                    <LanguageSelector />
                                </li>
                                <!-- currency selector -->
                                <li class="right-nav-list">
                                    <CurrencySelector />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="top-nav top-header sticky-header">
                <div class="container-fluid-lg">
                    <div class="row">
                        <div class="col-12">
                            <div class="navbar-top">
                                <button class="navbar-toggler d-xl-none d-inline navbar-menu-button" type="button"
                                    data-bs-toggle="offcanvas" data-bs-target="#primaryMenu">
                                    <span class="navbar-toggler-icon">
                                        <i class="fa-solid fa-bars"></i>
                                    </span>
                                </button>
                                <!-- logo -->
                                <LogoIcon />

                                <div class="middle-box">
                                    <div class="search-box">
                                        <div class="category-dropdown">
                                        <div class="category-title">
                                            <form method="post" ref="SearchForm" @submit.prevent="SearchSubmit">
                                                <div class="input-group">
                                                    <input type="search" class="form-control" :placeholder="searchPlaceholder" v-model="searchQuery"
                                                        name="searchValue"
                                                        aria-label="Recipient's username"
                                                        aria-describedby="button-addon2"
                                                        @focus="showDropdown = true"
                                                        @blur="hideDropdown" autocomplete="off"
                                                    />
                                                    <button class="btn" type="submit" id="button-addon2">
                                                        <i data-feather="search"></i>
                                                    </button>
                                                </div>
                                            </form>
                                            
                                        </div>
                                        <div class="dropdown-menus2 col-md-5" v-if="showDropdown && filteredItems.length > 0" @mousedown.prevent >
                                            
                                            <div class="row align-items-center mb-3">
                                                <div class="col d-flex justify-content-end">
                                                    <a class="text-capitalize link-primary" @click="clearallHistory">Clear all</a>
                                                    
                                                </div>
                                            </div>
                                            <div> 
                                                <a class="dropdown-items" v-for="data in filteredItems" :key="data.name" @click="RedirectShopPage(data)">
                                                    {{ data.name }}
                                                </a>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="rightside-box">
                                    <div class="search-full">
                                        <div class="input-group">
                                            <form method="post" ref="SearchForm" @submit.prevent="SearchSubmit">
                                                <div class="input-group">
                                                    <input type="search" class="form-control" :placeholder="searchPlaceholder" v-model="searchQuery"
                                                        name="searchValue"
                                                        aria-label="Recipient's username"
                                                        aria-describedby="button-addon2"
                                                        @focus="showDropdown = true"
                                                        @blur="hideDropdown" autocomplete="off"
                                                    />
                                                    <button class="btn" type="submit" id="button-addon2">
                                                        <i data-feather="search"></i>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <ul class="right-side-menu">
                                        <li class="right-side">

                                            <div class="delivery-login-box">
                                                <div class="delivery-icon">
                                                    <div class="search-box">
                                                        <i data-feather="search"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="right-side">
                                            <div class="location-box">
                                                <!-- delivery location -->
                                                <DeliveryLocation />
                                            </div>
                                        </li>

                                        <li class="right-side">
                                            <!-- wishlist -->
                                            <!-- <WishlistIcon/> -->
                                            <a @click="redirectTowishList" class="btn p-0 position-relative header-wishlist">
                                                <i data-feather="heart"></i>
                                            </a>
                                        </li>
                                        <li class="right-side">
                                            <div class="onhover-dropdown header-badge">
                                                <!-- cart icon -->
                                                <CartIcon :count="count" />
                                                <!-- cart dropdown -->

                                                <div class="onhover-div" v-show="loginStatus" v-if="cardData.length > 0">
                                                    <ul class="cart-list">
                                                        <CartDropdown v-for="data in cardData" :key="data.id" :item="data" />
                                                    </ul>

                                                    <div class="button-group">
                                                        <RouterLink to="/cart" class="btn btn-sm cart-button">View Cart</RouterLink>
                                                        <!-- <RouterLink to="/checkout" class="btn btn-sm cart-button theme-bg-color text-white">Checkout</RouterLink> -->
                                                    </div>
                                                </div>                                             

                                            </div>
                                        </li>
                                        <li class="right-side onhover-dropdown">
                                            <!-- user icon -->
                                            <UserIcon />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid-lg">
                <div class="row">
                    <div class="col-12">
                        <div class="header-nav">
                            <div class="header-nav-left">
                                
                                <!-- category set -->
                                <CategorySet />
                            </div>

                            <div class="header-nav-middle">
                                <div class="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
                                    <div class="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu">
                                        <div class="offcanvas-header navbar-shadow">
                                            <h5>Menu</h5>
                                            <button class="btn-close lead" type="button" data-bs-dismiss="offcanvas"
                                                aria-label="Close"></button>
                                        </div>
                                        <div class="offcanvas-body">
                                            <ul class="navbar-nav">
                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle home" @click="handleRouterLinkClick('Freedelivery')"> {{ $t("freedelivery") }}
                                                    </a>
                                                </li>

                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle home" @click="handleRouterLinkClick('Todaydeal')">{{ $t("todaydeals") }}</a>
                                                </li>

                                                <!-- <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle" href="javascript:void(0)"
                                                        data-bs-toggle="dropdown">For Events</a>
                                                    <ul class="dropdown-menu" style="margin-right: 1000px !important;">
                                                        <li>
                                                            <a class="dropdown-item" href="/">
                                                                Events Tickets Booking </a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="/">
                                                                Gift Items</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="/">
                                                                Decorating Items</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="/">
                                                                Event Arrangement Pre-order</a>
                                                        </li>
                                                    </ul>
                                                </li> -->


                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle home" @click="handleRouterLinkClicknew('Customizeproducts')" 
                                                      >{{ $t("personalizedgift") }}</a>
                                                </li>

                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle home" @click="handleRouterLinkClicknew('Bestsellers')">{{ $t("bestseller") }}
                                                    </a>
                                                </li>

                                                <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle home" @click="handleRouterLinkClicknew('Newarrivals')">{{ $t("newarrivals") }}
                                                    </a>
                                                </li>

                                                <!-- <li class="nav-item dropdown">
                                                    <a href="#" class="nav-link dropdown-toggle home"> Pre order products</a>
                                                </li> -->



                                                <!-- <li class="nav-item dropdown">
                                                    <a class="nav-link dropdown-toggle" href="javascript:void(0)"
                                                        data-bs-toggle="dropdown">Pre Order Product</a>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a class="dropdown-item" href="seller-become.html">Become a
                                                                Seller</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="seller-dashboard.html">Seller
                                                                Dashboard</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="seller-detail.html">Seller
                                                                Detail</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="seller-detail-2.html">Seller
                                                                Detail 2</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="seller-grid.html">Seller Grid</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" href="seller-grid-2.html">Seller Grid
                                                                2</a>
                                                        </li>
                                                    </ul>
                                                </li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid-lg" id="mobileLocation">
                <div class="row">
                    <div class="col-12 mt-1 mb-2">
                        <div class="header-nav">
                            <DeliveryLocationMobile />
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- Header End -->
    </div>
</template>
<style scoped>
.nav-link header .navbar.navbar-expand-xl .navbar-nav .nav-link {
        font-size: 17px !important;
    }
.btn-close1 {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 5px;
    margin-top: 5px;
    margin-right: 5px;
    color: #fff;
    background-image: url(../assets/images/icon/closeicon.png);
    background-color: #5480cb;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
}
.clear-text{
    cursor:pointer;
    margin-top: 5px;
}
.close-icon{
    font-size: 14px;
    position: absolute;
    top: 0px;
    right: 0px;
    /* border: 1px solid; */
    float: inline-end;
    border-radius: 25%;
}
.history-list{
    display: inline-block;
    /* padding-left: 10px; */
    margin-left: 16px;
    border: 2px solid var(--theme-color);
}
.align-self-center ul{
    padding-left:20px;
}
.home::before{
    content: "" !important;
}
.dropdown-menus {
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: block;
    float: left;
    width: 72%;
    height: -moz-max-content;
    height: max-content;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}
.dropdown-menus2 {
    position: absolute;
    top: 100%;
    left: 236px;
    z-index: 1000;
    display: block;
    float: left;
    height: 500px;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    overflow-y: scroll;
}

.dropdown-items {
  display: block;
  width: auto;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.dropdown-items-container {
  max-height: 400px; /* Set a max height for the container */
  overflow-y: scroll; /* Add vertical scroll bar if needed */
}
.dropdown-items:hover, .dropdown-items:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.search-box a{
    text-decoration: none;
    color: #3a3e4a;
    margin-left: 30px;
}

.align-self-center ul a{
    text-decoration: none;
    color: #3a3e4a;
    margin-left: 0px !important;
}
.text-align-right {
    float: right;
}

.search-img{
    width: 100%;
    cursor: pointer;
    align-items: center;
    text-align: center;
}
.line{
    border-left: 1px solid #ebebeb;
    width: 1px;
    margin-right: 24px;
    margin-left: 8px;
}

.search-title{
    font-size: 18px;
    color: #191919;
    line-height: 24px;
    font-weight: 700;
    margin: 0;
    margin-left: 40px;
}

.active-category{
    color: var(--bs-blue) !important;
}

@media (max-width: 767px){
    header .header-nav .header-nav-left .category-dropdown {
        display:none;
    }

}
@media (min-width: 767px){
    #mobileLocation {
        display:none;
    }
    
}
.dropdown-subcategory{
    border-radius: 8px;
    width: 100px;
    height: 130px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
}

@media (min-width: 1200px){
header .navbar-expand-xl.navbar .offcanvas-body {
    overflow: visible;
    margin-left: -20px;
}
header .navbar.navbar-expand-xl .navbar-nav .nav-link {
    font-size: 17px;
}
}

.link-primary{
    cursor: pointer;
    color:var(--theme-color)
}

header .top-nav .navbar-top .middle-box .search-box .input-group button {
    background-color: var(--theme-color);
    color: #fff;
    padding: 14px 20px;
    border-radius: 5px;
    border: 1px solid #228aad;
}
</style>