<template>
   
    <a @click="gotoHome" class="web-logo nav-logo">
        <img  v-if="FrontendImage" :src="baseUrl + FrontendImage.image" alt="Logo" class="img-fluid blur-up lazyload">
    </a>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
export default {
  name: 'LogoIcon',
  props: {
    // Your props here
  },
  data() {
    return {
      FrontendImage: [],
    };
  },
  computed: {
    
  },
  methods: {
    async fetchFrontendImage() {
        var logo = 'logo';
      try {
        const response = await axios.get(`frontendimage?area_label=${logo}`);
        this.FrontendImage = response.data;
        Cookies.set('CookieFrontendImage', JSON.stringify(this.FrontendImage), { expires: this.expDay });
      } catch (error) {
        console.error('Error fetching Frontend image:', error);
      }
    },
    gotoHome(){
        this.$router.push('/')
        .catch(() => {})
        .finally(() => {
            window.location.reload();
        });
    }
  },
  mounted() {
    if(Cookies.get("CookieFrontendImage")){
      this.FrontendImage = JSON.parse(Cookies.get('CookieFrontendImage'));                    
    }else{
      this.fetchFrontendImage();        
    }
  },
};

</script>

<style scoped>
.web-logo{
  cursor: pointer;
}
</style>