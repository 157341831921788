<template>


    <div id="mobilefix-menu">

        <!-- Location Modal Start -->
            <MobileSearch/>
        <!-- Location Modal End -->
        
            <!-- mobile fix menu start -->
    <div class="mobile-menu d-md-none d-block mobile-cart">
        <ul>
            <li>
                <a  @click="gotoHome">
                    <i class="iconly-Home icli"></i>
                    <span>Home</span>
                </a>
            </li>

            <li>
                <a @click="redirectToCategory">
                    <i class="iconly-Category icli js-link"></i>
                    <span>Category</span>
                </a>
            </li>

            <li>
                <a class="search-box" data-bs-toggle="modal" data-bs-target="#MobileSearchModalDisplay">
                    <i class="iconly-Search icli"></i>
                    <span>Search</span>
                </a>
            </li>

            <li>
                <a @click="redirectTowishList" class="notifi-wishlist">
                    <i class="iconly-Heart icli"></i>
                    <span>My Wish</span>
                </a>
            </li>

            <li>
                <a @click="redirectToCart">
                    <i class="iconly-Bag-2 icli fly-cate"></i>
                    <span>Cart</span>
                </a>
            </li>
        </ul>
    </div>
    <!-- mobile fix menu end -->
    </div>
</template>

<script>
import Cookies from 'js-cookie';
import MobileSearch from '../HeaderNav/MobileSearch.vue'

export default {
    name: 'mobilefix-menu',
    components:{
        MobileSearch 
    },
    data() {
        return {
            loginStatus:false,
        };
    },
    methods: {
        gotoHome(){
            this.$router.push('/')
            .catch(() => {})
            .finally(() => {
                window.location.reload();
            });
        },
        redirectTowishList(){
            if(this.loginStatus == true){
                window.open('/shop/Wishlist', '_blank');
            }else{
                this.$router.push('/login');
            }
        },
        redirectToCart(){
            if(this.loginStatus == true){
                this.$router.push({ path: '/cart' })
                .catch(() => {})
                .finally(() => {
                    // Reload the window
                    window.location.reload();
                });
            }else{
                this.$router.push('/login');
            }
        },
        redirectToSearch(){
            this.$router.push({ path: '/mobile-search' })
                .catch(() => {})
                .finally(() => {
                    // Reload the window
                    window.location.reload();
                });
        },
        redirectToCategory(){
            this.$router.push({ path: '/mobile-category' })
                .catch(() => {})
                .finally(() => {
                    // Reload the window
                    window.location.reload();
                });
        },
   
    },
  mounted() {
    if (Cookies.get("LoginStatus") == 'true') {
       // var customer_id = Cookies.get("CustomerLoginId");
        this.loginStatus = true;
    }
  },
}
</script>
<style scoped>

</style>