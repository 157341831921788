<template>
    <div class="notification-slider">
        <div>
            <div class="timer-notification">
                <h6>
                    {{ TopmessageData }}
                </h6>
            </div>
        </div>

        <div>
            <div class="timer-notification">
                <h6> {{ BottommessageData }}
                    <!-- <a href="shop-left-sidebar.html" class="text-white">Buy Now
                        !</a> -->
                </h6>
            </div>
        </div>
    </div>
</template>
<script>
import Cookies from 'js-cookie';
import axios from 'axios';

export default {
    data() {
        return {
            FrontData: [],
        }
    },
    computed: {
        TopmessageData(){

        const TopmessageItems = this.FrontData.filter(item => item.field_name === 'top_message');
        const TopmessageDataArray = TopmessageItems.map(item => item.data);
        return TopmessageDataArray.length > 0 ? TopmessageDataArray[0] : '';

        },
        BottommessageData(){

        const BottommessageItems = this.FrontData.filter(item => item.field_name === 'bottom_message');
        const BottommessageDataArray = BottommessageItems.map(item => item.data);
        return BottommessageDataArray.length > 0 ? BottommessageDataArray[0] : '';

        },
    },
    methods: {
        async fetchCategoryDatas() {
        try {
          const response = await axios.get('home/welcomecategorydata');
          this.categoryDatas = response.data;
        } catch (error) {
          console.error('Error fetching Category data:', error);
        }
      },
    },
    mounted() {
        if (Cookies.get('CookiesFrontenddata')) {
        this.FrontData = JSON.parse(Cookies.get('CookiesFrontenddata'));
    }
    }
}
</script>
<style scoped>

</style>