export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeeee"])},
  "deals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealsss"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "profileinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infor sur le profil"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasins"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des produits"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evénements"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "productReviewRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d'évaluation du produit"])},
  "eventReviewRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de révision d'événement"])},
  "findlocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver un emplacement"])},
  "storeReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Store Review"])}
}