import { createStore } from 'vuex';

const store = createStore({
    state() {
        console.log(sessionStorage.getItem('isUserLoggedIn')??'test', 'from session');
        let isUserLoggedIn = sessionStorage.getItem('isUserLoggedIn')??false;
        if(isUserLoggedIn == 'false'){
            isUserLoggedIn = false;
        }
        return {
            selectedCountry: '',
            countryName: '',
            countryCapital: '',
            loggedIn: sessionStorage.getItem('loggedIn')??false,
            userId: sessionStorage.getItem('userId')??null,
            vendorloggedIn: sessionStorage.getItem('vendorloggedIn')??false,
            vendorloggedInId: sessionStorage.getItem('vendorloggedInId')??null,
            isUserLoggedIn: isUserLoggedIn,
            loginUserlanguage:'',
        };
    },
    mutations: {
        setLoginUserLanguage(state,languageCode){
            state.loginUserlanguage = languageCode;
        },
        setSelectedCountry(state, country) {
            state.selectedCountry = country;
        },
        setCountryInfo(state, { name, capital }) {
            state.countryName = name;
            state.countryCapital = capital;
        },
        login(state, { userId }) {
            state.loggedIn = true;
            state.userId = userId;
            state.isUserLoggedIn = true;
        },

        logout(state) {
            state.loggedIn = false;
            state.userId = null;
            state.isUserLoggedIn = false;
        },
        vendorlogin(state, { vendorId }) {
            state.vendorloggedIn = true;
            state.vendorloggedInId = vendorId;
            state.isUserLoggedIn = true;
        },

        vendorlogout(state) {
            state.vendorloggedIn = false;
            state.vendorloggedInId = null;
            state.isUserLoggedIn = false;
        },
    },
    actions: {
        updateSelectedCountry({ commit }, country) {
            commit('setSelectedCountry', country);
        },
        updateCountryInfo({ commit }, { name, capital }) {
            commit('setCountryInfo', { name, capital });
        },
        setUserLanguageCode({ commit }, languageCode) {
            commit('setLoginUserLanguage', languageCode);
        },
    },
    getters: {
        selectedCountry(state) {
            return state.selectedCountry;
        },
        storedCountryName(state) {
            return state.countryName;
        },
        storedCountryCapital(state) {
            return state.countryCapital;
        },
        storedLogin(state) {
            return state.loggedIn;
        },
        storeUserId(state) {
            return state.userId;
        },
        storedvendorLogin(state) {
            return state.vendorloggedIn;
        },
        storeloggedInvendorId(state) {
            return state.vendorloggedInId;
        },
        getUserLanguageCode: (state) => state.loginUserlanguage,
    },
});

export default store;
