<template>
   <!-- <div class="dropdown theme-form-select">
        <button class="btn dropdown-toggle" type="button" id="select-language"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="assets/images/country/united-states.png"
                class="img-fluid blur-up lazyload" alt="">
            <span>{{$i18n.locale}}</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="select-language">
            <li v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
                <a class="dropdown-item" href="javascript:void(0)" id="english">
                    <img src="assets/images/country/united-kingdom.png"
                        class="img-fluid lazyload" alt="">
                    <span>English</span>
                </a>
            </li>
        </ul>
    </div> -->
    <select  v-model="$i18n.locale" class="theme-form-select" @change="updateLanguage">

        <option v-for="(locale, i) in languagesCode" :key="`locale-${i}`" :value="locale" style="color: #645b5b;">
            
                    <!-- <img :src="baseUrl + data.flag"
                        class="img-fluid lazyload" alt="flag"> -->
                    <span>{{ getLanguageFullName(locale) }}</span>
                
            
        </option>
    </select>

</template>

<script>
import { useStore } from 'vuex';
import Cookies from 'js-cookie';
import axios from 'axios';
export default {
    name: "language-selector",
    data() {
        return {
             locales: ["fr", "en","du"],
             languagesData: [],  
             languagewithflag:[], 
             cookieLanguage:'',
             languagesCode:[]
       // $i18n.locale: this.LoginUserLanguageCode

     };
      
    },
    props: {
        LoginUserLanguageCode: {
           type: String,
            default: "en"
        },
      
    },
     computed: {
       storedLoginUserLanguage() {
            const store = useStore();

            return store.getters.getUserLanguageCode;
        },

    },
     watch: {
        LoginUserLanguageCode(newValue) {
            // Update the selectedLocale when LoginUserLanguageCode changes
            this.$i18n.locale = newValue;
        },
        cookieLanguage(newValue){
            Cookies.set("CookiesLanguageCode", newValue, { expires: 6 });
            this.$emit('selected-language-event', newValue);
            this.clearCookiesAndLocalStorage();
            this.$router.push({
                    path: '/'
                })
                .catch(() => {})
                .finally(() => {
                    // Reload the window
                    window.location.reload();
                });           
        }
    },
    methods: {
        updateLanguage(){
            this.cookieLanguage = this.$i18n.locale;
                // Store the selected language in a cookie with a 6-day expiration
                
           
        },
        getLanguageFullName(locale) {
            // Add logic here to return the full name based on the locale
            switch (locale) {
                case "fr":
                    return "Français";
                case "en":
                    return "English";
                case "du":
                    return "Nederlands";
                case "ta":
                    return "தமிழ்";
                case "si":
                    return "සිංහල";
                case "de":
                    return "Deutsch";
                case "es":
                    return "Spanish";
                case "it":
                    return "Italiano";
                case "ja":
                    return "Japanese";
                case "no":
                    return "norsk";
                case "ru":
                    return "Русский";
                case "ae":
                    return "عربي";
                default:
                    return "";
            }
        },
         async fetchLanguagedata() {
           
            try {
                const response = await axios.get('Language/list'); 
                  this.languagesCode = response.data.language_codes;
                  this.languagesData = response.data.language_data;
                  this.setItemWithExpirationCommon('CookieLanguagecodes', this.languagesCode, this.expDay);
                  this.setItemWithExpirationCommon('CookieLanguageData', this.languagesData, this.expDay);
                  
            } catch (error) {
                console.error('Error fetching language data:', error);
            }
        },
        async fetchDefaultLanguage() {
            try {
              const response = await axios.get('home/getDefaultLanguage');
              this.$i18n.locale = response.data;
              Cookies.set("CookiesLanguageCode", response.data, { expires: this.expDay });
             // console.log('lang res',Cookies.get("CookiesLanguageCode"));
            } catch (error) {
              console.error('Error fetching Default language data:', error);
            }
          },
         
        
    },
     mounted() {
      // console.log('cookieLanguage',Cookies.get("CookiesLanguageCode"))

       if(Cookies.get("CookiesLanguageCode")){
        this.$i18n.locale = Cookies.get("CookiesLanguageCode"); 
       }else{
            this.fetchDefaultLanguage();          
        }

        if(this.getItemWithExpirationCommon('CookieLanguagecodes')){
            this.languagesCode = this.getItemWithExpirationCommon('CookieLanguagecodes');
            if(this.getItemWithExpirationCommon('CookieLanguageData')){
                this.languagesData = this.getItemWithExpirationCommon('CookieLanguageData');
            }           
        }else{
            this.fetchLanguagedata();        
        }
        
    }
};
</script>
<style scoped>
.theme-form-select span{
    color:Black;
}
</style>